@import "../../../styles/variables.scss";

.suggestion-item {
  display: flex;
  align-items: center;
  gap: $space-md;

  cursor: pointer;

  .portrait {
    width: 50px;
    height: 50px;
  }

  .highlight {
    font-weight: $font-weight-bold;
  }
}
