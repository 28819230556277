@import "../../styles/mixin.scss";
@import "../../styles/variables.scss";

.request-list {
  background-color: #f5f5f5;
  box-shadow: -5px 0 5px -5px rgba(0, 0, 0, 0.5),
    5px 0 5px -5px rgba(0, 0, 0, 0.5), 0 5px 5px -5px rgba(0, 0, 0, 0.5);
}

.status {
  display: flex;
  align-items: center;
  gap: $space-sm;

  div {
    width: 5px;
    height: 5px;
    border-radius: 50%;
  }
}

@include button-group(flex-start, $space-xs);

.view-details-button {
  text-align: left;
  text-decoration: underline;
  background: none;
  border: none;

  cursor: pointer;
}
