@import "../../styles/mixin.scss";

@include section-wrapper(column, 40px);

.subheading {
  font-size: 1.17rem;
  font-weight: 700;
  margin-bottom: 2rem;
}

.tab-selector {
  width: 100%;
}

@include button-group(flex-start, 20px);
