@import "../../../../styles/variables.scss";

.icon-editable-card {
  width: 100%;
  min-height: 200px;
  display: flex;
  align-items: center;
  padding: $space-xl $space-md $space-md $space-md;
  gap: $space-sm;

  .subtitle,
  .description {
    border-bottom: 1px solid rgba(0, 0, 0, 0);
  }

  .subtitle {
    margin-bottom: $space-sm;
  }

  .description {
    font-weight: $font-weight-bold;
  }

  input,
  textarea {
    width: 100%;
    margin: 0;
    padding: 0;

    color: $secondary-color;
    border-bottom: solid $secondary-color;

    border-width: 0 0 1px 0;
    text-align: center;

    &:nth-child(2) {
      color: $secondary-color;
      margin-bottom: $space-sm;
    }

    &:nth-child(3) {
      color: $secondary-color;
      font-weight: $font-weight-bold;

      resize: none;
    }
  }
}
