@import "../../styles/variables.scss";

.card-container {
  position: relative;

  display: flex;
  flex-direction: column;

  flex: 1;

  border: 1px dashed #000;

  .card-title {
    font-size: $font-size-h1;
    font-weight: $font-weight-extra-bold;
    color: $secondary-color;
  }
}

.controls {
  display: flex;

  position: absolute;
  right: 5px;
  top: 5px;

  .control-btn-group {
    display: flex;
    gap: $space-xs;
  }

  .control-btn {
    width: 25px;
    height: 25px;

    cursor: pointer;

    &:nth-child(2) {
      color: $third-color;
    }
  }
}
