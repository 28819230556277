@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "./styles/variables.scss";
@import "./styles/custom-bootstrap.scss";
@import "react-datepicker/dist/react-datepicker.css";
@import "suneditor/dist/css/suneditor.min.css";

* {
  box-sizing: border-box;
}

body {
  height: 100vh;
  margin: 0;

  font-family: "Poppins", sans-serif;
  color: $secondary-color;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

input,
textarea {
  font-family: inherit;
  outline: none;
}

ul,
ol {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

button {
  padding: $space-sm $space-lg;
  border: none;
}

.error {
  color: $error-color;
}

// React Date Picker

.react-datepicker-popper {
  z-index: 100;
  font-family: inherit;
}

.react-datepicker {
  font-family: inherit;
}

.react-datepicker__header {
  background-color: $primary-color;
}

.react-datepicker__day--selected {
  background-color: $primary-color;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header,
.react-datepicker__day-name {
  color: #fff;
}

iframe {
  display: none;
}
