@import "../../../styles/variables.scss";

.hero-image-container {
  min-height: 323px;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  background-size: cover;
  background-position: center;
  transition: all $transition-fast ease;
  border: 1px dashed #000;

  &.drag-active {
    border: 5px dashed $third-color;
    border-radius: 75px;
  }
}

.hero-content {
  position: relative;
  text-align: center;
  z-index: 1;

  .hero-text {
    margin-bottom: 1rem;

    color: $fourth-color;
    font-size: $font-size-base;
    font-weight: $font-weight-bold;

    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  }
}

.change-image-button {
  border-radius: $border-radius-sm;

  cursor: pointer;
  transition: background-color $transition-fast ease;
}

// @media (max-width: 991px) {
//   .heroImageContainer {
//     padding: 100px 20px;
//   }

//   .changeImageButton {
//     padding: 11px 20px;
//   }
// }
