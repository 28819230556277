@import "../../../styles/variables.scss";

.invite-form {
  // margin-top: 16px;
  padding: $space-md;

  .invite-label {
    margin-bottom: $space-md;
  }

  .invite-input-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $space-md;

    .select-wrapper {
      flex: 1;
      min-width: 240px;
    }

    .invite-button {
      padding: 0.375rem 0.75rem;
      color: $fourth-color;
    }
  }
}

// @media (max-width: 991px) {
//   .inviteForm {
//     max-width: 100%;
//   }
// }
// .inviteForm {
//   background-color: #fff;
//   border-radius: 16px;
//   padding: 16px;
//   margin-top: 13px;
// }

// .inviteLabel {
//   color: #0a171f;
//   font: 400 12px/1.4 Inter, sans-serif;
//   letter-spacing: 0.24px;
//   display: block;
//   margin-bottom: 16px;
// }

// .inviteInputGroup {
//   display: flex;
//   align-items: center;
//   gap: 16px;
//   flex-wrap: wrap;
// }

// .inviteInput {
//   flex: 2;
//   min-width: 200px;
//   padding: 12px 14px;
//   border-radius: 12px;
//   border: 1px solid #e0dad9;
//   background-color: #fff;
//   font: 14px/1.4 Lato, sans-serif;
//   color: #344054;
// }

// .inviteSelect {
//   flex: 1;
//   min-width: 120px;
//   padding: 12px 14px;
//   border-radius: 12px;
//   border: 1px solid #e0dad9;
//   background-color: #fff;
//   font: 14px/1.4 Lato, sans-serif;
//   color: #344054;
// }

// .invite-button {
//   // padding: 11px 12px;
//   border-radius: 8px;
//   color: #fff;

//   cursor: pointer;
// }

// .sendIcon {
//   width: 14px;
//   height: 14px;
// }

// @media (max-width: 991px) {
//   .inviteForm {
//     max-width: 100%;
//   }

//   .inviteInputGroup {
//     flex-direction: column;
//     align-items: stretch;
//   }

//   .inviteInput,
//   .inviteSelect,
//   .inviteButton {
//     width: 100%;
//   }
// }
