// Colors
$primary-color: #27aae1;
$secondary-color: #2a2c3a;
$third-color: #d7dce2;
$fourth-color: #fff;
$error-color: #ff0084;
$disabled-color: #807a7a;

// Font Sizes
$font-size-small: 0.75rem; // 12px
$font-size-base: 1rem; // 16px
$font-size-large: 1.5rem; // 24px
$font-size-h1: 2rem; // 32px
$font-size-h2: 1.75rem; // 28px

// Font Weights
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;

// Spacing (in rem)
$space-xs: 0.25rem; // 4px
$space-sm: 0.5rem; // 8px
$space-md: 1rem; // 16px
$space-lg: 2rem; // 32px
$space-xl: 4rem; // 64px
$space-xxl: 6rem; // 96px

// Border Radius
$border-radius-sm: 0.25rem; // 4px
$border-radius-md: 0.5rem; // 8px
$border-radius-lg: 1rem; // 16px
$border-radius-xl: 2rem; // 32px

// Transitions
$transition-fast: 0.2s;
$transition-medium: 0.5s;
$transition-slow: 1s;

// Layout (in rem)
$max-width-container: 75rem; // 1200px
$min-width-container: 20rem; // 320px
