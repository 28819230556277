@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.content-wrapper {
  max-width: 1323px;
  width: 100%;

  display: flex;
  align-items: stretch;
  flex: 1;
}

.desktop-nav {
  @include mobile-screen {
    display: none;
  }
}

.outlet {
  width: 80%;
  max-width: 991px;
  padding: $space-xl $space-lg;
  display: flex;
  flex-direction: column;
  gap: $space-xl;

  flex: 1;
}

// @media (max-width: 991px) {
//   .desktop-nav {
//     display: none;
//   }
// }
