@import "../../styles/mixin.scss";
@import "../../styles/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: $space-lg;

  .heading {
    font-weight: $font-weight-bold;
  }

  .filter-section {
    display: flex;
    flex-direction: column;
    gap: $space-md;

    .section-title {
      color: #5a5a5b;
      font-weight: $font-weight-semi-bold;
      font-size: $font-size-base;
    }
  }

  @include button-group(flex-start, $space-md);
}
