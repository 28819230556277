@import "../../../styles/variables.scss";

.editable-card {
  padding: $space-md $space-md $space-lg $space-md;
  gap: $space-sm;

  input {
    width: 100%;
    margin: 0;
    padding: 0;

    border-bottom: solid $secondary-color;
    border-width: 0 0 1px 0;
    font-size: $font-size-base;
    color: $secondary-color;

    &:nth-of-type(1) {
      font-size: $font-size-h1;
      font-weight: $font-weight-bold;
    }

    &:nth-of-type(2) {
      font-weight: $font-weight-semi-bold;
    }
  }

  .hero-message-title,
  .hero-message-subtitle {
    border-bottom: 1px rgba(0, 0, 0, 0) solid;
  }

  .hero-message-title {
    font-size: $font-size-h1;
    font-weight: $font-weight-bold;
  }

  .hero-message-subtitle {
    font-weight: $font-weight-semi-bold;
  }
}
