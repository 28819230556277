@import "../../../../styles/variables.scss";

.editable-card {
  padding: $space-md $space-md $space-lg $space-md;
  gap: $space-sm;

  input {
    width: 100%;
    margin: 0;
    padding: 0;

    border-bottom: solid $secondary-color;
    border-width: 0 0 1px 0;
    font-size: $font-size-base;
    color: $secondary-color;

    &:nth-of-type(1),
    &:nth-of-type(3) {
      font-weight: $font-weight-semi-bold;
    }
  }

  .cta-title {
    font-size: $font-size-h1;
    font-weight: $font-weight-extra-bold;
    color: $secondary-color;
  }

  .cta-subtitle,
  .cta-description,
  .cta-cta {
    font-size: $font-size-base;
    border-bottom: 1px rgba(0, 0, 0, 0) solid;
  }

  .cta-subtitle,
  .cta-cta {
    font-weight: $font-weight-semi-bold;
  }
}
