@import "../../../../styles/variables.scss";

.promoter-list {
  background-color: #f6f6f6;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.access-button {
  color: $fourth-color;
}

.view-events-button {
  text-decoration: underline;
  background: none;
  border: none;

  cursor: pointer;
}

.send-icon {
  width: 16px;
  height: 16px;
  margin-right: $space-xs;
}

.promoter-label,
.promoter-name {
  color: #5a5a5b;
  font-weight: $font-weight-semi-bold;
}

.promoter-name {
  margin-bottom: $space-md;
}
