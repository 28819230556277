@import "../../styles/variables.scss";

.option-list {
  background-color: #f6f6f6;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

// .reportHeader {
//   background-color: #f6f6f6;
//   display: flex;
//   justify-content: flex-end;
//   padding: 21px 80px;
// }

// .reportLogo {
//   aspect-ratio: 2.33;
//   object-fit: contain;
//   object-position: center;
//   width: 121px;
// }

.option-item {
  display: flex;
  align-items: center;
  gap: $space-sm;

  padding: $space-lg;

  &:not(:last-child) {
    border-bottom: 0.5px solid $secondary-color;
  }

  .filter-button {
    margin-left: auto;
    display: flex;
    gap: $space-xs;

    border-radius: 60px;
  }

  .option-checkbox {
    position: relative;
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid $primary-color;
    border-radius: $border-radius-sm;
    outline: none;
    cursor: pointer;

    &:checked {
      background-color: $primary-color;

      &::after {
        content: "\2714";
        font-size: 14px;
        color: white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .option-label {
    width: calc(100% - 20px);

    .option-description {
      margin-top: $space-xs;

      color: #838383;
      font-size: $font-size-small;
      font-style: italic;
    }
  }
}

// @media (max-width: 991px) {
//   .reportList {
//     max-width: 100%;
//   }

//   .reportHeader {
//     padding: 21px 20px;
//   }

//   .reportLogo {
//     max-width: 100%;
//   }

//   .reportItem {
//     padding: 28px 20px;
//   }

//   .reportDescription {
//     max-width: 100%;
//   }
// }
