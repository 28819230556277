@import "../../../styles/variables.scss";

.checkbox-label {
  display: flex;
  align-items: center;
  gap: $space-sm;

  font-weight: 500;

  cursor: pointer;

  .toggle-button-container {
    width: 40px;
    height: 20px;
    position: relative;

    .checkbox {
      width: 40px;
      height: 20px;
      position: absolute;
      appearance: none;

      border-radius: 10px;
      border: 1px solid $secondary-color;
      background-color: #fff;

      cursor: pointer;
      z-index: 1;

      &:checked {
        background-color: #27aae1;
        border-color: #27aae1;
      }
    }

    .toggle-button {
      width: 14px;
      height: 14px;
      position: absolute;
      top: 3px;
      left: 5px;

      border-radius: 50%;
      border: 1px solid $secondary-color;
      background-color: $fourth-color;

      transition: all $transition-fast ease;
      z-index: 50;

      &.active {
        left: 21px;
        border: 1px solid transparent;
      }
    }
  }

  // .checkbox-text {
  //   color: #737373;
  // }
}
