@import "../../../styles/variables.scss";

.section-wrapper {
  // margin-bottom: 40px;

  .title {
    font-weight: $font-weight-bold;
    color: #000;
    margin-bottom: $space-sm;
  }

  .description {
    font-size: $font-size-base;
    color: #000;
    margin-bottom: $space-md;
  }
}
