@import "../../../../styles/variables.scss";
@import "../../../../styles/mixin.scss";

.textarea {
  min-height: 200px;
}

@include button-group(flex-end, $space-md);

// .action-buttons {
//   margin-top: 30px;
//   display: flex;
//   gap: $space-md;
//   justify-content: flex-end;
//   flex-direction: row-reverse;

//   button {
//     width: 150px;
//   }
// }
