@import "../../styles/variables.scss";

.tab-selector {
  display: flex;
  // width: 544px;
  max-width: 100%;

  .tab {
    padding: $space-md 0;
    position: relative;

    flex: 1;

    border-radius: 0 $border-radius-xl 0 0;
    border: 1px solid $primary-color;
    text-align: center;
    font-size: $font-size-base;
    font-weight: $font-weight-semi-bold;
    background-color: $third-color;
    color: #fefffe;

    cursor: pointer;

    &.active-tab {
      background-color: $primary-color;
    }
  }
}
