@import "../../../../styles/variables.scss";

.list {
  background-color: #f6f6f6;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.overwrite-button {
  color: $fourth-color;
}
