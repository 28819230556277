@import "../../../styles/variables.scss";

.invitation-item {
  padding: $space-sm;

  display: flex;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid $secondary-color;
}
