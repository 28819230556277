@import "../../styles/variables.scss";

.slider {
  -webkit-appearance: none;
  appearance: none;

  height: 0.4rem;
  border-radius: $border-radius-sm;
  background: $third-color;
  outline: none;

  cursor: pointer;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: $primary-color;
  }

  &::-moz-range-thumb {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: $primary-color;
  }
}
