@import "../../styles/variables.scss";

.editable-content {
  .input-label,
  .textarea-label {
    margin-bottom: $space-xs;

    color: #3a2a2d;
  }

  input,
  textarea {
    width: 100%;

    border: 1px solid #8d8d8d;
    border-radius: $border-radius-sm;
    font-family: inherit;
  }

  input {
    margin-bottom: $space-md;
    padding: $space-sm $space-lg;
  }

  textarea {
    // min-height: 500px;
    padding: $space-md $space-lg;
  }
}
