@import "../../../styles/variables.scss";
@import "../../../styles/mixin.scss";

.login-form {
  width: 600px;
  padding: 100px 125px;
  margin: $space-xl auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: $space-md;

  background-color: $third-color;

  h1 {
    color: $primary-color;
  }

  @include mobile-screen {
    width: 80vw;
    padding: 100px 10vw;
  }
}
