@import "../../../styles/variables.scss";

.nav {
  width: 300px;
  height: 100%;
  padding: $space-lg;

  background-color: $third-color;

  .links-group {
    width: 100%;
    position: sticky;
    top: $space-md;
    display: flex;
    flex-direction: column;
    gap: $space-md;

    border-radius: $border-radius-sm;
  }

  .other-links {
    padding: 0 $space-md;
    margin-top: $space-lg;

    display: flex;
    flex-direction: column;
    gap: $space-md;

    a {
      display: flex;
      align-items: center;
      gap: $space-sm;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}

// @media (max-width: 991px) {
//   .nav {
//     display: none;
//   }
// }
