@import "../../../styles/variables.scss";
@import "../../../styles/mixin.scss";

.admin-list-container {
  display: flex;
  flex-direction: column;
  gap: $space-lg;

  .admin-list {
    background-color: #f5f5f5;
    border-radius: $border-radius-lg;
    padding: $space-md;
    // margin-top: 32px;

    .title {
      margin-bottom: $space-md;
    }

    ul {
      .admin-item {
        padding: $space-md 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        border-bottom: 1px solid #e0e0e0;

        &:last-child {
          border-bottom: none;
        }

        @include mobile-screen {
          flex-direction: column;
          align-items: flex-start;
          gap: $space-sm;
        }

        .admin-info {
          display: flex;
          align-items: center;
          gap: $space-md;

          .admin-profile-picture {
            width: 32px;
            height: 32px;
            border-radius: 50%;
          }
        }

        .role-admin {
          width: 150px;
          padding: $space-sm $space-md;

          text-align: center;
          color: $secondary-color;
          border-radius: $border-radius-md;
          background-color: $third-color;
        }

        .role-toggle {
          width: 150px;
          color: $fourth-color;
        }
      }
    }
  }

  @include button-group(flex-end, 20px);
}
