@import "../../styles/variables.scss";

.default-user-icon {
  width: 32px;
  height: 32px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
  background-color: $primary-color;
  color: $fourth-color;
  font-weight: $font-weight-bold;
}
