@import "../../../styles/variables.scss";

.accordion-title {
  padding: $space-md;
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: $space-sm;

  font-size: $font-size-base;
  font-weight: bold;
  border-radius: $border-radius-sm;

  cursor: pointer;
  z-index: 100;
}

.active-section {
  background: linear-gradient(to right, #27aae1 0%, #e3e4e6 100%);
  color: $fourth-color;
}

.accordion-arrow {
  transition: transform $transition-fast ease-in-out;
}

.rotate-up {
  transform: rotate(180deg);
}

.accordion-content {
  display: flex;
  flex-direction: column;
  align-items: center;

  // background: #9dccdf;
  transform: translateY(-5px);
  z-index: 1;
}

.accordion-content a {
  width: 100%;
  padding: $space-sm $space-lg;

  font-size: $font-size-base;

  cursor: pointer;

  &:nth-child(1) {
    padding: $space-md $space-lg $space-sm $space-lg;
  }
}

.active {
  background: #67afce;
  font-weight: $font-weight-bold;
}

// .navSection {
//   margin-bottom: 20px;
// }

// .nav-header button {
//   background-color: red;
// }

// .sectionTitle {
//   font-size: 16px;
//   font-weight: 600;
//   color: #2a2c3a;
//   margin-bottom: 10px;
// }

// .navList {
//   list-style-type: none;
//   padding: 0;
// }

// .navItem {
//   font-size: 14px;
//   color: #2a2c3a;
//   padding: 8px 0;
//   cursor: pointer;
// }
