@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.modal {
  width: 100%;
  padding: $space-xl;
  position: relative;
  border: none;

  @include mobile-screen {
    padding: 5vw;
  }

  .close-icon {
    position: absolute;
    top: 40px;
    right: 40px;

    fill: #5a5a5b;

    cursor: pointer;

    @include mobile-screen {
      top: $space-md;
      right: $space-md;
    }
  }
}
