@import "../../../styles/variables.scss";
@import "../../../styles/mixin.scss";

.header {
  width: 100%;
  // min-height: 96px;
  padding: 0 $space-lg 0 $space-md;

  display: flex;
  align-items: center;

  background-color: $secondary-color;

  .logo {
    width: clamp(200px, 30vw, 350px);
    margin: $space-md 0;
    // height: 64px;
  }

  .icon-container {
    margin-left: auto;

    display: flex;

    .profile-picture {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      object-fit: cover;
    }

    button {
      background-color: transparent;

      svg {
        width: 32px;
        height: 32px;

        color: white;
      }
    }

    .menu-btn {
      display: none;

      @include mobile-screen {
        display: block;
      }
    }
  }
}
