@import "../../styles/variables.scss";

.language-picker {
  width: fit-content;
  position: relative;

  span {
    font-size: $font-size-small;
  }

  .btn {
    width: 90px;
    height: 40px;
    padding: $space-sm;
    margin: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: $space-sm;

    border-radius: 20px;
    border: none;
    background-color: $third-color;
    text-transform: uppercase;

    .arrow {
      transition: transform $transition-fast ease;

      &.rotate {
        transform: rotate(90deg);
      }
    }
  }

  .modal {
    width: fit-content;
    height: auto;
    position: absolute;
    top: calc(100% + $space-sm);
    right: 0;

    display: none;
    background-color: $third-color;

    border-radius: $border-radius-md;
    overflow: hidden;
    z-index: 100;

    &.active {
      display: flex;
      flex-direction: column;
    }

    .lang-btn {
      width: 100%;
      padding: $space-sm $space-md;

      display: flex;
      align-items: center;
      gap: 10px;

      background-color: $third-color;
      color: $secondary-color;
      text-transform: uppercase;

      &:hover {
        background-color: $primary-color;
        color: $fourth-color;
      }
    }
  }
}
