@import "../../styles/mixin.scss";
@import "../../styles/variables.scss";

.container {
  width: 100%;
  display: flex;
  gap: $space-md;
  // justify-content: space-between;

  @include mobile-screen {
    flex-direction: column;
  }

  p {
    font-weight: $font-weight-semi-bold;
  }

  .date-picker-group {
    display: grid;
    grid-template-columns: auto-fit 1fr;
    grid-template-rows: 1fr 1fr;
    gap: $space-sm;
    align-items: center;

    div:last-child {
      grid-column: 2 / 3;
      grid-row: 2 / 3;
    }
  }
}
