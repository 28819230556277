.error-content {
  width: 100%;
  height: 300px;
  padding: 50px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  .heading {
    display: flex;
    align-items: center;
    gap: 5px;

    svg {
      width: 3rem;
      height: 3rem;

      opacity: 0.5;
    }
  }
}
