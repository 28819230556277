@import "../../../../styles/variables.scss";

.icon-items {
  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;

  .title {
    font-size: $font-size-large;
    font-weight: $font-weight-bold;
    color: $secondary-color;
    margin-bottom: $space-sm;
  }

  .icon {
    width: 72px;
    height: 72px;
    margin-bottom: $space-sm;
  }
}
