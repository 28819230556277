@import "../../../../styles/variables.scss";

.mobile-nav-backdrop {
  width: 100%;
  height: 100vh;

  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  justify-content: flex-end;

  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);

  z-index: 101;

  .mobile-nav {
    // width: 360px;
    height: 100%;
    // padding: 50px 0;
    overflow: auto;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    background-color: $third-color;

    animation: slide-in $transition-medium ease-in-out forwards normal;

    .close-btn {
      margin: $space-md $space-md 0 0;
      align-self: flex-end;
      // position: absolute;
      // top: 20px;
      // right: 20px;
      background-color: transparent;

      .close-icon {
        fill: #5a5a5b;
      }
    }
  }
}

@keyframes slide-in {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}
