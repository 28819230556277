@import "../../../styles/variables.scss";

.accordion-title {
  padding: $space-md;

  font-weight: $font-weight-bold;
  border-radius: $border-radius-sm;

  cursor: pointer;
}

.active-section {
  background: linear-gradient(to right, #27aae1 0%, #e3e4e6 100%);
  color: $fourth-color;
}

// .active-section-items {
//   background: #67afce;
// }

// .accordion-content {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// }

// .accordion-content a {
//   width: 100%;
//   padding: 10px 30px;

//   //   text-align: center;
//   cursor: pointer;
//   // color: #0366d6;
// }

// .active {
//   background: red;
//   font-weight: 700;
// }

// .navSection {
//   margin-bottom: 20px;
// }

// .nav-header button {
//   background-color: red;
// }

// .sectionTitle {
//   font-size: 16px;
//   font-weight: 600;
//   color: #2a2c3a;
//   margin-bottom: 10px;
// }

// .navList {
//   list-style-type: none;
//   padding: 0;
// }

// .navItem {
//   font-size: 14px;
//   color: #2a2c3a;
//   padding: 8px 0;
//   cursor: pointer;
// }
