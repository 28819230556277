@import "../../styles/mixin.scss";
@import "../../styles/variables.scss";

.questions-container {
  display: flex;
  flex-direction: column;
  gap: $space-lg;
}

.textarea {
  min-height: 200px;
}

@include button-group(flex-end, 20px);
