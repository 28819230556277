@import "../../../styles/variables.scss";

.selected-item {
  height: 30px;
  padding: 0 $space-sm;
  margin-top: $space-sm;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: $space-xs;

  background-color: $third-color;
  border-radius: $border-radius-lg;

  span {
    font-size: $font-size-small;
  }

  .close-icon {
    fill: #5a5a5b;

    cursor: pointer;
  }
}
