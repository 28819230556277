@import "../../../../styles/variables.scss";

.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: $space-md;
}
// .editable-card {
//   gap: 10px;
//   padding-bottom: 50px;

//   input {
//     margin: 0;
//     padding: 0;

//     color: #2a2c3a;

//     border: none;

//     &:nth-child(3) {
//       font-size: 20px;
//       font-weight: 600;
//       color: #2a2c3a;
//     }

//     &:nth-child(4) {
//       font-size: 16px;
//       color: #2a2c3a;
//     }

//     &:nth-child(5) {
//       font-size: 16px;
//       font-weight: 500;
//       color: #2a2c3a;
//     }
//   }
// }

// .cta-card {
//   flex: 1;
//   border: 1px dashed #000;
//   padding: 20px;
//   display: flex;
//   flex-direction: column;
// }

// .cta-title {
//   font-size: 32px;
//   font-weight: 800;
//   color: #2a2c3a;
// }

// .cta-subtitle {
//   font-size: 20px;
//   font-weight: 600;
//   color: #2a2c3a;
// }

// .cta-description {
//   font-size: 16px;
//   color: #2a2c3a;
// }

// .cta-cta {
//   font-size: 16px;
//   font-weight: 500;
//   color: #2a2c3a;
// }
// @media (max-width: 991px) {
//   .ctaCardContainer {
//     flex-direction: column;
//   }
// }
