@import "../../styles/variables.scss";

.button {
  width: 180px;
  padding: $space-sm $space-md;

  display: flex;
  align-items: center;
  gap: $space-sm;

  border-radius: $border-radius-sm;
  font-weight: $font-weight-semi-bold;

  transition: background-color $transition-fast ease;
  cursor: pointer;

  svg {
    color: $primary-color;
  }

  &:disabled {
    opacity: 0.3;
  }
}

.active {
  background-color: $primary-color;
  color: $fourth-color;
  border: none;

  &:hover {
    background-color: #1f8fc2;
  }

  svg {
    color: $fourth-color;
  }
}

.inactive {
  background-color: $fourth-color;
  color: $disabled-color;
  border: 1px solid #e6e6e6;

  svg {
    color: $disabled-color;
  }
}
