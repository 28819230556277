@import "../../../styles/variables.scss";

.image-container {
  &.drag-active {
    // border: 5px dashed $third-color;
    border-radius: 75px;
  }
}

.preview-area {
  display: flex;
  flex-wrap: wrap;
  gap: $space-md;
}

.content {
  position: relative;
  text-align: center;

  z-index: 1;

  &.has-image {
    color: $fourth-color;
  }

  .content-text {
    margin-bottom: $space-md;

    font-size: $font-size-large;
    font-weight: $font-weight-semi-bold;
  }
}

.change-image-button {
  border-radius: $border-radius-sm;

  cursor: pointer;
  transition: background-color $transition-fast ease;
}
