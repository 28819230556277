@import "../../../styles/variables.scss";

.button {
  border-radius: $border-radius-sm;
  font-weight: $font-weight-semi-bold;

  transition: background-color $transition-fast ease;
  cursor: pointer;

  &:disabled {
    background-color: $disabled-color;
    opacity: 0.3;
    cursor: not-allowed;

    &:hover {
      background-color: $disabled-color;
    }
  }
}

.primary {
  background-color: $primary-color;
  color: $fourth-color;
  border: none;

  &:hover {
    background-color: #1f8fc2;
  }
}

.secondary {
  background-color: $fourth-color;
  color: $secondary-color;
  border: 1px solid $secondary-color;

  &:hover {
    background-color: $third-color;
  }
}

.third {
  background-color: #d9d9d9;
  color: $secondary-color;
  border: none;
}

.inactive {
  background-color: $fourth-color;
  color: $disabled-color;
  border: 1px solid #e6e6e6;
}

// @media (max-width: 991px) {
//   .button {
//     padding: 10px 20px;
//     font-size: 14px;
//   }
// }
