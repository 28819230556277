// Layout

@mixin mobile-screen {
  @media screen and (max-width: 991px) {
    @content;
  }
}

@mixin section-wrapper($direction, $gap) {
  .section-wrapper {
    display: flex;
    flex-direction: #{$direction};
    gap: #{$gap};
  }
}

// Components

@mixin text-input() {
  .text-input {
    width: 100%;
    padding: $space-sm;
    border: 1px solid #ccc;
    border-radius: $border-radius-sm;
  }
}

@mixin select-input() {
  .select-input {
    width: 100%;
    padding: $space-sm;
    border: 1px solid #ccc;
    border-radius: $border-radius-sm;
  }
}

@mixin button-group($justify-content, $gap) {
  .action-buttons-#{$justify-content} {
    display: flex;
    gap: #{$gap};
    justify-content: #{$justify-content};
  }
}

// Animation

@mixin fade-in($duration) {
  opacity: 0;
  animation: fade-in #{$duration} ease-in-out forwards;

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
