@import "../../styles/mixin.scss";
@import "../../styles/variables.scss";

.category-container {
  width: 50%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: $space-sm $space-xs;

  @include mobile-screen {
    grid-template-columns: repeat(3, 1fr);
  }

  .category-button {
    width: 100px;
    border-radius: $border-radius-lg;
  }
}
