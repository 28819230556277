@import "../../../styles/variables.scss";

.search-sort {
  display: flex;
  justify-content: space-between;
}

.dropdown-toggle {
  width: 150px;
  border: 1px solid $secondary-color;

  &:hover {
    border: 1px solid $secondary-color;
  }
}
